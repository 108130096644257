define('web/services/vip-parker', ['exports', 'ember', 'ic-ajax', 'web/mixins/serialize-record', 'web/config/environment'], function (exports, _ember, _icAjax, _webMixinsSerializeRecord, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    flashMessages: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    lots: null,
    duplicateVipParkers: _ember['default'].A(),
    postedParkers: null,

    // Creates multiple VIP Parkers.
    createParkers: function createParkers(params) {
      var _this = this;

      this.set('postedParkers', params.parkers);

      // Loop over parkers in the form and make a POST to create each VIP Parker.
      return _ember['default'].RSVP.all(params.parkers.map(function (parker) {
        _this.set('lots', params.lotIds);

        var payload = {
          operatorId: params.operatorId,
          vipParker: {
            eventId: params.eventId,
            lotIds: params.lotIds,
            exemptionId: params.exemptionId,
            firstName: parker.get('firstName'),
            lastName: parker.get('lastName'),
            status: 'active'
          }
        };

        // If we are allowing duplicate VIP Parker creation.
        if (params.allowCreation) {
          payload.allowCreation = params.allowCreation;
        }

        return _this.create(payload);
      }));
    },

    // Creates individual VIP Parker.
    create: function create(payload) {
      var _this2 = this;

      return (0, _icAjax['default'])({
        method: 'POST',
        url: _webConfigEnvironment['default'].apiUrl + '/api/v3/vipParkers',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(payload),
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }).then(function (resp) {
        // Check if the posted VIP Parker is a duplicate.
        if (_this2.isDuplicate(resp.vipParker)) {
          // Add the VIP Parker to a managed array of duplicate parkers.
          _this2.pushDuplicateRecords(resp.vipParker);
        }

        return resp;
      })['catch'](function (err) {
        _this2.get('flashMessages').danger(err.jqXHR.responseJSON.message || 'VIP Parkers were not created successfully.');

        _ember['default'].logger.error(err);

        throw err;
      });
    },

    // Push the record into the duplicateVipParkers array.
    pushDuplicateRecords: function pushDuplicateRecords(vipParker) {
      // Set the orgionally posted lotIds instead of the returned lotIds for duplicate VIP Parkers.
      vipParker.lotIds = this.get('lots').toArray();

      // Serialize record into ember model.
      var record = (0, _webMixinsSerializeRecord.serialize)(this.get('store'), 'vip-parker', vipParker);

      this.get('duplicateVipParkers').pushObject(record);
    },

    resetState: function resetState() {
      this.get('lots').clear();
      this.get('postedParkers').clear();
      this.get('duplicateVipParkers').clear();
    },

    canClear: function canClear() {
      return this.get('lots.length') !== 0 && this.get('postedParkers.length') !== 0 && this.get('duplicateVipParkers.length') !== 0;
    },

    // Helpers
    isDuplicate: function isDuplicate(vipParker) {
      return vipParker.isDuplicate;
    },

    anyDuplicateParkers: function anyDuplicateParkers() {
      return this.get('duplicateVipParkers').length > 0;
    },

    allDuplicateParkers: function allDuplicateParkers() {
      return this.get('postedParkers').length === this.get('duplicateVipParkers').length;
    },

    oneDuplicateParker: function oneDuplicateParker() {
      return this.get('duplicateVipParkers').length === 1;
    }
  });
});
define("web/helpers/tax-math", ["exports", "ember"], function (exports, _ember) {
    exports.additionalChargesMath = additionalChargesMath;

    function isFixedRate(type) {
        return type === "FIXED_RATE";
    }

    function additionalChargesMath() {
        return {
            isFixedRate: isFixedRate,
            convertIn: function convertIn(type, number) {
                return isFixedRate(type) ? parseFloat(number) : parseFloat(number);
            },
            convertOut: function convertOut(type, number) {
                return isFixedRate(type) ? parseFloat(number) : parseFloat(Math.round(number * Math.pow(10, 6)) / Math.pow(10, 6));
            },
            setType: function setType(type) {
                return isFixedRate(type) ? _ember["default"].Object.create({ id: "FIXED_RATE", name: "Amount" }) : _ember["default"].Object.create({ id: "PERCENTAGE", name: "Percentage" });
            },
            setPlaceholder: function setPlaceholder(type, country) {
                return isFixedRate(type) ? country === 'GB' ? '£' : '$' : '%';
            }
        };
    }

    exports["default"] = _ember["default"].Helper.helper(additionalChargesMath);
});
define('web/controllers/region/details', ['exports', 'ember', 'web/mixins/record-editor', 'web/mixins/image-uploader-controller', 'web/config/environment', 'ic-ajax'], function (exports, _ember, _webMixinsRecordEditor, _webMixinsImageUploaderController, _webConfigEnvironment, _icAjax) {
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var equal = _Ember$computed.equal;
  var debounce = _ember['default'].run.debounce;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var observer = _ember['default'].observer;

  var m = _webConfigEnvironment['default'].mapbox;
  var mapboxPlaces = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/';
  var types = 'place,postcode';
  var tokenString = '.json?types=' + types + '&access_token=' + m.accessToken;

  var BaseMarkerOptions = {
    icon: {
      iconSize: [16, 16],
      popupAnchor: [0, -16]
    }
  };

  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], _webMixinsImageUploaderController['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: alias('model.id'),
    recordName: 'region',
    recordModel: 'region',
    recordTransitionTo: 'regions',
    availableCountries: availableCountries,
    selectedCountry: computed('model.country', function () {
      if (!this.get('model.country')) {
        return null;
      }

      return this.get('availableCountries').findBy('id', this.get('model.country'));
    }),

    isGlobalRegion: equal('model.isGlobal', true),

    markerOptions: computed(function () {
      var markerLocationOptions = copy(BaseMarkerOptions);
      markerLocationOptions.icon.iconUrl = 'assets/images/map-point-blue.svg';

      return markerLocationOptions;
    }),

    _getLatLon: function _getLatLon(zip) {
      var _this = this;

      if (zip.length === 5) {
        (0, _icAjax['default'])('' + mapboxPlaces + this.get('model.name') + ' ' + zip + tokenString).then(function (resp) {
          if (resp.features[0]) {
            _this.set('model.location', _ember['default'].Object.create({
              lon: resp.features[0].center[0],
              lat: resp.features[0].center[1]
            }));
          }
        });
      }
    },

    convertMiToKm: observer('model.radius', function () {
      debounce(this, this._convert, this.get('model.radius'), 750);
    }),

    _convert: function _convert(rad) {
      this.set('miToKm', parseInt(rad) * 1.60934 * 1000);
    },

    actions: {
      changeZip: function changeZip(zip) {
        if (_ember['default'].isBlank(zip)) {
          this.set('model.zip', null);
          this.set('model.location', null);
        } else {
          this.set('model.zip', zip);
          debounce(this, this._getLatLon, zip, 750);
        }
      },

      toggleLive: function toggleLive() {
        this.toggleProperty('model.live');
      },
      changeCountry: function changeCountry(country) {
        this.set('model.country', country.id);
        this._setLatLong(this.get('model.zip'));
      }
    }

  });
});
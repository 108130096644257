define('web/routes/create/landmark', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ['name', 'slug', 'address1', 'address2', 'city', 'state', 'zip', 'location', 'directions', 'information', 'country'],
    recordBelongsTo: ['region'],

    model: function model() {
      return {};
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        regionSearch: null,
        errorMessages: null
      });
    }
  });
});
define('web/adapters/event-option', ['exports', 'ember-data', 'ember', 'web/config/environment'], function (exports, _emberData, _ember, _webConfigEnvironment) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    shouldReloadRecord: function shouldReloadRecord(store, snapshot) {
      return true;
    },

    shouldReloadAll: function shouldReloadAll(store, snapshot) {
      return true;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord(store, snapshot) {
      return true;
    },

    shouldBackgroundReloadAll: function shouldBackgroundReloadAll(store, snapshot) {
      return true;
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var event = {
        live: null,
        eventOptions: {
          optOut: null,
          lotsSellable: []
        }
      };

      var id = snapshot.event.id;
      var lotIds = [];

      var lotsSellable = snapshot.event.eventOptions.lotsSellable;
      if (lotsSellable) {
        lotsSellable.forEach(function (lot) {
          lotIds.push(lot.get('id'));
        });

        event.eventOptions.lotsSellable = lotIds;
      }

      var eventOptionId = snapshot.event.eventOptions.id;
      if (eventOptionId) {
        event.eventOptions.id = eventOptionId;
      }

      var live = snapshot.event.live;
      if (live !== null) {
        event.live = live;
      }

      var optOut = snapshot.event.eventOptions.optOut;
      if (optOut !== null) {
        event.eventOptions.optOut = optOut;
      }

      return this.ajax(_webConfigEnvironment['default'].apiUrl + '/api/v3/events/' + id, "PUT", {
        contentType: 'application/json',
        dataType: 'json',
        data: {
          event: event
        },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    }
  });
});
define('web/controllers/modals/export-csv', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    orders: _ember['default'].inject.controller('orders'),

    isModalOpen: alias('application.isModalOpen'),
    currentPollDateTo: alias('orders.currentPollDateTo'),
    dateFrom: alias('orders.dateFrom'),
    dateTo: alias('orders.dateTo'),
    search: alias('orders.search'),

    csvCreated: false,
    csvLink: null,
    barcodeChecked: false,

    url: (function () {
      return this.get('model.url');
    }).property('model.url'),

    actions: {
      exportCSV: function exportCSV(callback) {
        var _this = this;

        this.set('isExporting', true);

        var exportParams = _ember['default'].Object.create({
          report: {
            dateFrom: moment(this.get('dateFrom')).toDate(),
            dateTo: moment(this.get('dateTo')).toDate()
          }
        });

        if (this.get('search')) {
          exportParams.report.search = this.get('search');
        }

        if (this.get('currentPollDateTo')) {
          exportParams.report.dateTo = moment(this.get('currentPollDateTo')).toDate();
        }

        if (this.get('barcodeChecked')) {
          exportParams.report.tm = true;
          exportParams.report.search = 'prepaid';
        }

        var promise = (0, _icAjax['default'])({
          method: 'POST',
          url: _webConfigEnvironment['default'].apiUrl + '/api/v3/reports',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(exportParams),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        });

        callback(promise);

        promise.then(function (resp) {
          _this.setProperties({
            csvCreated: true,
            csvLink: resp.url
          });
        })['catch'](function () {
          return _this.flashMessages.danger('Sorry, there was an error exporting this CSV.');
        });
      },

      download: function download() {
        window.open(this.get('csvLink'), '_blank');
      },

      closeCsvModal: function closeCsvModal() {
        this.setProperties({
          csvCreated: false,
          isExporting: false,
          barcodeChecked: false
        });
        this.send('closeModal');
      },

      updateBarcodeChecked: function updateBarcodeChecked(val, checked) {
        this.set('barcodeChecked', checked);
      }
    }
  });
});
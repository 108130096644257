define('web/controllers/modals/forgot-password', ['exports', 'ember', 'ember-validations', 'ic-ajax', 'web/mixins/error-message', 'web/config/environment'], function (exports, _ember, _emberValidations, _icAjax, _webMixinsErrorMessage, _webConfigEnvironment) {
  var later = _ember['default'].run.later;
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'].Mixin, _webMixinsErrorMessage['default'], {
    application: _ember['default'].inject.controller('application'),
    isModalOpen: alias('application.isModalOpen'),

    errorMessages: null,

    validations: {
      email: {
        presence: true,
        format: {
          'with': /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Must be a valid e-mail address.'
        }
      }
    },

    actions: {
      submitReset: function submitReset() {
        var _this = this;

        this.validate().then(function () {
          _this.set('isSubmitting', true);
          _this.set('messageSent', false);

          var user = _ember['default'].Object.create({
            user: {
              email: _this.get('email')
            }
          });

          (0, _icAjax['default'])({
            method: 'POST',
            url: _webConfigEnvironment['default'].apiUrl + '/api/v3/passwords',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(user),
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            }
          }).then(function (resp) {
            _this.set('isSubmitting', false);
            _this.set('messageSent', true);
            _this.setProperties({
              email: null
            });

            later(function () {
              _this.set('messageSent', false);
              _this.send('closeModal');
            }, 1500);
          })['catch'](function (err) {
            _ember['default'].Logger.error('error post to pw: ', err);
            _this.set('isSubmitting', false);
            _this._errors(err);
            // this.set('errorMessages', ['Sorry, this user is not a part of our database.']);
          });
        }, function (err) {
          _this.set('errorMessages', err.email);
          _ember['default'].Logger.error('validations error: ', err);
        });
      },

      clearErrorMessage: function clearErrorMessage() {
        if (this.get('errorMessages')) {
          this.set('errorMessages', null);
        }
      }
    }
  });
});
define('web/models/lot', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;

  // this all comes from the database or is calculated from the database attributes

  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    slug: attr('string'),
    address1: attr('string'),
    address2: attr('string'),
    city: attr('string'),
    state: attr('string'),
    zip: attr('string'),
    location: attr('object'),
    distance: attr('string'),
    live: attr('boolean'),
    claimed: attr('boolean'),
    totalSpots: attr('number'),
    autoSell: attr('boolean'),
    directions: attr('string'),
    information: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    assignAllAttendants: attr('boolean'),
    assignAllNewAttendants: attr('boolean'),
    ticketmasterLineNumber: attr('string'),
    country: attr('string'),
    presell: attr('boolean'),
    sellable: attr('boolean'),
    inventoryTypePath: attr('string'),
    metaTagFlag: attr('string'),
    pricingIds: hasMany('pricing', { async: true }),
    subOperators: hasMany('user', { async: true }),
    landmarks: hasMany('landmark', { async: true }),
    amenities: hasMany('amenity', { async: true }),
    testimonials: hasMany('testimonial', { async: true }),
    reservations: hasMany('reservation', { async: false }),
    events: hasMany('event', { async: false }),
    assignments: hasMany('user', { async: true }),

    paymentProcessorId: belongsTo('payment-processor', { async: true }),
    operator: belongsTo('user', { async: true }),
    regionId: belongsTo('region', { async: true }),

    images: hasMany('image', { async: true }),

    externalLotNames: attr('array'),

    trimId: (function () {
      var id = this.get('id');
      return id.slice(-4);
    }).property('id'),

    spotsSold: computed('reservations.[].spotsSold', {
      get: function get() {
        return this.get('reservations').reduce(function (sum, res) {
          return parseInt(sum) + parseInt(_get(res, 'spotsSold'));
        }, 0);
      }
    })

  });
});
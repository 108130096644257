define("web/controllers/create/lot", ["exports", "ember", "web/mixins/state-options", "web/mixins/record-create", "web/mixins/set-lat-lon-controller", "web/helpers/slugify", "web/utils/country-options"], function (exports, _ember, _webMixinsStateOptions, _webMixinsRecordCreate, _webMixinsSetLatLonController, _webHelpersSlugify, _webUtilsCountryOptions) {
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Controller.extend(_webMixinsStateOptions["default"], _webMixinsRecordCreate.ControllerRecordCreate, _webMixinsSetLatLonController["default"], {
    selectedProcessor: computed("availablePaymentProcessors.[].isDefault", {
      get: function get() {
        var availProcessors = this.get("availablePaymentProcessors");
        var setProcessor = availProcessors.find(function (pp) {
          return pp.get("isDefault");
        });
        this.set("model.paymentProcessorId", setProcessor);
        return setProcessor;
      },
      set: function set(k, newProcessor) {
        this.set("model.paymentProcessorId", newProcessor);
        return newProcessor;
      }
    }),

    selectedMetatag: "default",

    /* determine available metatags to show in front end. */
    availableMetatagLabels: computed("metatags", {
      get: function get() {
        /* get metatag objects from lot.js route */
        var metatagObject = this.get("metatags");
        this.set("model.selectedMetatag", "Parking"); //Parking is the default

        /* ensure metatag service is running */
        if (metatagObject.children.length === 0) {
          return [];
        } else {
          /* get an array of labels from the objects */
          var metatagLabels = metatagObject.children.map(function (response) {
            return response.displayName;
          });
          return metatagLabels;
        }
      },
      set: function set(k, newMetatag) {
        this.set("model.selectedMetatag", newMetatag);
        return newMetatag;
      }
    }),
    availableCountries: _webUtilsCountryOptions.availableCountries,
    selectedCountry: computed('model.country', function () {
      if (!this.get('model.country')) {
        return null;
      }

      return this.get('availableCountries').findBy('id', this.get('model.country'));
    }),
    searches: ["landmark", "subOperators", "operator", "regionId"],
    query: function query(text, selectedItems, cb, type, _ref) {
      var exclude = _ref.exclude;
      var role = _ref.role;

      var q = text;
      var l = this.getWithDefault("limit", 5);

      var suggestions;

      var params = { search: q, limit: l };

      if (type === "landmark") {
        params.dBSearch = true;
      }

      if (_ember["default"].isPresent(exclude)) {
        params.exclude = exclude;
      }
      if (_ember["default"].isPresent(role)) {
        params.role = role;
      }

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember["default"].Logger.error("failed to get results for", q, reason);
      });
    },

    actions: {
      create: function create() {
        var _this = this;

        if (!this.get("model.landmarks.length")) {
          this.flashMessages.danger("Landmark is required.");
          return false;
        }

        if (!this.get("model.operator") && this.get("currentUser.isAdmin")) {
          this.flashMessages.danger("Operator is required.");
          return false;
        }
        /* Get the selected label and available metatags */
        var metatagObject = this.get("model.metatags");

        if (metatagObject.children.length != 0) {
          var _ret = (function () {
            // make sure metatag service isn't down

            var metatags = metatagObject.children;
            var selectedMetatag = _this.get("model.selectedMetatag");

            /* if the metatag service is up, set the default metatag path to be parking. */
            if (!_this.get("model.selectedMetatag")) {
              _this.set("model.selectedMetatag", "tag.inventory_type.parking");
            }

            /* If the metatag service is up and current user is NOT an admin, selectedMetatag is required. */
            if (!_this.get("model.selectedMetatag") && _this.get("currentUser.isAdmin")) {
              _this.flashMessages.danger("Inventory type is required.");
              return {
                v: false
              };
            }

            /* Find the metatag path that matches the displayName chosen */
            var metatagPath = metatags.find(function (element) {
              return element.displayName === selectedMetatag;
            });

            /* Make sure the metatag path is defined, otherwise it will thorugh an error */
            if (metatagPath) {
              metatagPath = metatagPath.path;
              _this.set("model.inventoryTypePath", metatagPath);
            } else {
              metatagPath = undefined;
              /* Set type to .parking if metatag doesn't populate */
              _this.set("model.inventoryTypePath", "tag.inventory_type.parking");
            }
          })();

          if (typeof _ret === "object") return _ret.v;
        }

        if (this.get("currentUser.isAdmin")) {
          _ember["default"].set(this.get("model"), "assignAllAttendants", null);
          _ember["default"].set(this.get("model"), "assignments", null);
        }

        return true;
      },

      toggleSell: function toggleSell() {
        this.toggleProperty("model.autoSell");
      },

      changeName: function changeName(name) {
        var slugged = (0, _webHelpersSlugify.slugify)(name);
        this.set("model.slug", slugged);
        this.set("model.name", name);
      },

      changeSlug: function changeSlug(slug) {
        this.set("model.slug", (0, _webHelpersSlugify.slugify)(slug));
      },

      changeZip: function changeZip(zip) {
        this.set("model.zip", zip);
        this._setLatLong(zip);
      },

      changeCountry: function changeCountry(country) {
        this.set('model.country', country.id);
        this._setLatLong(this.get('model.zip'));
      },

      subOpRequest: function subOpRequest(text, selectedItems, cb) {
        var s = selectedItems.mapBy("id");
        var r = "sub-operator";

        if (_ember["default"].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, "user", { exclude: s, role: r });
      },

      subOpFocused: function subOpFocused(curSelectedItems, cb) {
        var r = "sub-operator";

        if (_ember["default"].isBlank(curSelectedItems)) {
          this.query("*", null, cb, "user", { exclude: null, role: r });
        }
      },

      operatorRequest: function operatorRequest(text, selectedItems, cb) {
        var r = "operator";

        if (_ember["default"].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, "user", { exclude: null, role: r });
      },

      operatorFocused: function operatorFocused(curSelectedItems, cb) {
        var r = "operator";

        if (_ember["default"].isBlank(curSelectedItems)) {
          this.query("*", null, cb, "user", { exclude: null, role: r });
        }
      },

      landmarkRequest: function landmarkRequest(text, selectedItems, cb) {
        var s = selectedItems.mapBy("id");

        if (_ember["default"].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, "landmark", { exclude: s });
      },

      landmarkFocused: function landmarkFocused(curSelectedItems, cb) {
        if (_ember["default"].isBlank(curSelectedItems)) {
          this.query("*", null, cb, "landmark", {});
        }
      },

      regionRequest: function regionRequest(text, selectedItems, cb) {
        if (_ember["default"].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, "region", {});
      },

      regionFocused: function regionFocused(curSelectedItems, cb) {
        if (_ember["default"].isBlank(curSelectedItems)) {
          this.query("*", null, cb, "region", {});
        }
      },

      changeChecked: function changeChecked(newVal, checked) {
        if (checked) {
          return this.get("model.amenities").pushObject(newVal);
        }
        this.get("model.amenities").removeObject(newVal);
      }
    }
  });
});
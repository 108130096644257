define('web/controllers/modals/signup', ['exports', 'ember', 'web/mixins/recaptcha', 'ember-validations', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _webMixinsRecaptcha, _emberValidations, _icAjax, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecaptcha['default'], _emberValidations['default'].Mixin, {
    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),

    validations: {
      email: {
        presence: true,
        format: {
          'with': /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Must be a valid e-mail address.'
        }
      },
      password: {
        length: {
          minimum: 6,
          messages: {
            tooShort: 'Password must be more than 6 characters.'
          }
        }
      }
    },

    doPasswordsMatch: (function () {
      if (_ember['default'].isEqual(this.get('password'), this.get('passwordConfirm')) && _ember['default'].isPresent(this.get('password')) && _ember['default'].isPresent(this.get('passwordConfirm'))) {
        return true;
      }
      return false;
    }).property('password', 'passwordConfirm'),

    actions: {
      signUpUser: function signUpUser() {
        var self = this;

        if (self.get('doPasswordsMatch') && self.get('notARobot')) {
          self.validate().then(function () {

            self.set('isSubmitting', true);
            self.set('messageSent', false);

            var user = _ember['default'].Object.create({
              user: {
                email: self.get('email'),
                password: self.get('password')
              }
            });

            (0, _icAjax['default'])({
              method: 'POST',
              url: _webConfigEnvironment['default'].apiUrl + '/api/v3/users',
              contentType: 'application/json',
              dataType: 'json',
              data: JSON.stringify(user),
              crossDomain: true,
              xhrFields: {
                withCredentials: true
              }
            }).then(function (resp) {
              self.set('isSubmitting', false);
              self.set('messageSent', true);
              _ember['default'].Logger.log(resp);
              return self.get('session').open('parkhub', {
                email: self.get('email'),
                password: self.get('password')
              }).then(function (resp) {

                _ember['default'].run.later(function () {
                  self.send('closeModal');
                  self.set('messageSent', false);
                  self.setProperties({
                    email: null,
                    password: null,
                    passwordConfirm: null
                  });
                }, 3000);
                _ember['default'].Logger.log(resp);
              })['catch'](function (err) {
                self.set('errorMessages', [err.responseText.split('"')[9]]);
                _ember['default'].Logger.log(err);
              });
            })['catch'](function (err) {
              _ember['default'].Logger.log(err);
              self.set('isSubmitting', false);
            });
          }, function (err) {
            self.set('errorMessages', [err.password, err.email]);
            _ember['default'].Logger.error(err);
          });
        }
      },

      signInViaFacebook: function signInViaFacebook() {
        _ember['default'].Logger.log('Facebook');
        return this.get('session').open('facebook-connect');
      },

      clearErrorMessage: function clearErrorMessage() {
        var self = this;
        if (self.get('errorMessages')) {
          self.set('errorMessages', null);
        }
      }
    }
  });
});
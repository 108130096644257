define('web/components/additional-charges', ['exports', 'ember', 'web/config/environment'], function (exports, _ember, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['additional-charges'],
    landmarks: 'landmarks',
    allLandmarks: 'allLandmarks',
    saveState: 'saveState',
    showConfirmationModal: 'showConfirmationModal',
    additionalChargesEnabled: 'additionalChargesEnabled',
    applyForUpcomingEvents: 'applyForUpcomingEvents',
    acrossLocations: 'acrossLocations',
    perLocation: 'perLocation',
    transactionTypes: 'transactionTypes',
    landmarksSort: ['name:asc'],
    sortedLandmarks: _ember['default'].computed.sort('landmarks', 'landmarksSort'),
    additionalFeeText: _webConfigEnvironment['default'].text.additionalFeeText,
    upcomingEventsText: _webConfigEnvironment['default'].text.upcomingEventsText,
    applyforUpcomingEvents: _webConfigEnvironment['default'].text.applyforUpcomingEvents,
    applyAcrossLocations: _webConfigEnvironment['default'].text.applyAcrossLocations,
    paymentTypeText: _webConfigEnvironment['default'].text.paymentTypeText,
    enableAdditionalFees: _webConfigEnvironment['default'].text.enableAdditionalFees,
    applyPerLocations: _webConfigEnvironment['default'].text.applyPerLocations,
    landmarkDisabled: false,
    revenueShare: 'revenueShare',
    isAllSameCountry: _ember['default'].computed('allLandmarks.[]', function () {
      var allLandmarks = this.get('allLandmarks');

      if (!allLandmarks || allLandmarks.length === 0) {
        return true;
      }

      return allLandmarks.every(function (landmark) {
        return landmark.country === allLandmarks[0].country;
      });
    }),
    isCrossLocationAvailable: _ember['default'].computed('revenueShare', 'isAllSameCountry', function () {
      return this.get('revenueShare') === false && this.get('isAllSameCountry');
    }),
    hasInvalidName: false,

    feeOptions: _ember['default'].A([_ember['default'].Object.create({ id: "PERCENTAGE", name: "Percentage" }), _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" })]),

    selectedLandmark: null,

    actions: {
      addLandmark: function addLandmark() {
        if (!this.get('selectedLandmark')) {
          return this.flashMessages.danger('You must select a landmark from the dropdown.');
        }

        var landmarkId = this.get('selectedLandmark.id');
        var country = this.get('landmarks').findBy('id', landmarkId).country;
        var currencyPlaceholder = country.country === 'GB' ? '£' : '$';

        var record = _ember['default'].Object.create({
          landmark: this.get('selectedLandmark'),
          tax: _ember['default'].Object.create({
            type: _ember['default'].Object.create({ id: "PERCENTAGE", name: "Percentage" }),
            placeholder: "%",
            value: null,
            hasValueError: false
          }),
          fees: _ember['default'].Object.create({
            type: _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }),
            placeholder: currencyPlaceholder,
            records: _ember['default'].A()
          })
        });

        this.get('perLocation.records').pushObject(record);

        this.get("landmarks").removeObject(this.get('selectedLandmark'));

        if (this.get("landmarks.length") === 0) {
          this.set('landmarkDisabled', true);
        }

        this.set("selectedLandmark", null);
      },

      addFee: function addFee(record) {
        var landmarkId = record.landmark.id;
        var country = this.get('allLandmarks').findBy('id', landmarkId);
        var currencyPlaceholder = country.country === 'GB' ? '£' : '$';

        record.get('fees.records').pushObject(_ember['default'].Object.create({
          name: "",
          placeholder: currencyPlaceholder,
          type: _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }),
          hasValueError: false,
          isInvalidName: false,
          value: null
        }));
      },

      addCrossLocationFee: function addCrossLocationFee(record) {
        var country = this.get('allLandmarks')[0];
        var currencyPlaceholder = country.country === 'GB' ? '£' : '$';

        record.get('fees.records').pushObject(_ember['default'].Object.create({
          name: "",
          placeholder: currencyPlaceholder,
          type: _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }),
          hasValueError: false,
          isInvalidName: false,
          value: null
        }));
      },

      checkInputValidation: function checkInputValidation(record) {
        if (record.get('value')) {
          record.set('hasValueError', false);
        }

        var nameRecord = record.get('name');

        if (nameRecord) {
          if (['card', 'convenience', 'credit', 'surcharge'].some(function (word) {
            return nameRecord.toLowerCase().replace(/ /g, '').includes(word);
          })) {
            record.set('isInvalidName', true);
            this.set('hasInvalidName', true);
          } else {
            record.set('isInvalidName', false);
            this.set('hasInvalidName', false);
          }
        }
      },

      changeCrossLocationFeeType: function changeCrossLocationFeeType(fee, type) {
        var country = this.get('allLandmarks')[0];
        var currencyPlaceholder = country.country === 'GB' ? '£' : '$';

        if (type.get('id') === "PERCENTAGE") {
          fee.set('type', _ember['default'].Object.create({ id: "PERCENTAGE", name: "Percentage" }));
          fee.set('placeholder', "%");
        } else if (type.get('id') === "FIXED_RATE") {
          fee.set('type', _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }));
          fee.set('placeholder', currencyPlaceholder);
        }
      },

      changeFeeType: function changeFeeType(fee, landmark, type) {
        var country = this.get('allLandmarks').findBy('id', landmark.id);
        var currencyPlaceholder = country.country === 'GB' ? '£' : '$';

        if (type.get('id') === "PERCENTAGE") {
          fee.set('type', _ember['default'].Object.create({ id: "PERCENTAGE", name: "Percentage" }));
          fee.set('placeholder', "%");
        } else if (type.get('id') === "FIXED_RATE") {
          fee.set('type', _ember['default'].Object.create({ id: "FIXED_RATE", name: "Amount" }));
          fee.set('placeholder', currencyPlaceholder);
        }
      },

      deleteFee: function deleteFee(record, fee) {
        record.get('fees.records').removeObject(fee);
      },

      deleteTax: function deleteTax(record) {
        record.set('value', null);
      },

      deleteLandmark: function deleteLandmark(record) {
        this.get("landmarks").pushObject({ id: record.get('landmark.id'), name: record.get('landmark.name') });
        this.get('perLocation.records').removeObject(record);

        if (this.get("landmarks.length") > 0) {
          this.set('landmarkDisabled', false);
        }
      },

      saveState: function saveState() {
        this.sendAction('saveState', this.get('acrossLocations'), this.get('perLocation'), this.get('transactionTypes'));
      },

      toggleFeeEnabled: function toggleFeeEnabled(fee) {
        fee.toggleProperty('enabled');
      },

      toggleAdditionalCharges: function toggleAdditionalCharges() {
        this.sendAction('showConfirmationModal');
      },

      toggleCash: function toggleCash() {
        this.get('transactionTypes').toggleProperty('cash');
      },

      toggleCredit: function toggleCredit() {
        this.get('transactionTypes').toggleProperty('credit');
      },

      togglePayments: function togglePayments() {
        this.get('transactionTypes').toggleProperty('payment');
      }
    }
  });
});
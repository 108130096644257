define("web/controllers/event-parking/details", ["exports", "ember", "fetch", "web/config/environment"], function (exports, _ember, _fetch, _webConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember["default"].computed;

  var options = {
    findAllMatches: true,
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name']
  };

  var initialized = false;
  var initializedFuse = null;

  var fuse = function fuse(data) {
    if (initialized) {
      return initializedFuse;
    } else {
      initialized = true;
      initializedFuse = new Fuse([].concat(_toConsumableArray(data)), options);
      return initializedFuse;
    }
  };

  exports["default"] = _ember["default"].Controller.extend({
    modifyPricing: _ember["default"].inject.controller('modals.pricing'),
    confirmation: _ember["default"].inject.controller('modals.confirmation'),
    application: _ember["default"].inject.controller(),
    vipParkerUrl: _webConfigEnvironment["default"].vipParkerUrl,

    sortingBy: ['default:asc'],
    sortedLots: _ember["default"].computed.sort('model.lots', 'sortingBy'),

    pricingsConfiguredStat: computed('model.lots', function () {
      var count = 0;
      this.get('model.lots').forEach(function (lot) {
        if (!lot["default"]) {
          count++;
        }
      });

      return count;
    }),

    potentialRevenueHighStat: computed('model.lots', function () {
      var count = 0;
      this.get('model.lots').forEach(function (lot) {
        if (!lot["default"]) {
          count += lot.max * lot.lot.get('totalSpots');
        }
      });

      return count;
    }),

    potentialRevenueLowStat: computed('model.lots', function () {
      var count = 0;
      this.get('model.lots').forEach(function (lot) {
        if (!lot["default"]) {
          count += lot.min * lot.lot.get('totalSpots');
        }
      });

      return count;
    }),

    actions: {
      changeSearch: function changeSearch(search) {
        if (search === "") {
          this.set('model.lots', this.get('model.initialLots'));
          return;
        }

        var results = fuse(this.get('model.lots')).search(search);

        this.set('model.lots', results.map(function (r) {
          return r.item;
        }));
      },

      changePhoneNumber: function changePhoneNumber(pn) {
        if (_ember["default"].isPresent(pn)) {
          this.set("model.eventOptions.phoneNumber", pn);
        } else {
          this.set("model.eventOptions.phoneNumber", "");
        }
      },

      changeReceiptHeader: function changeReceiptHeader(rh) {
        if (_ember["default"].isPresent(rh)) {
          this.set("model.eventOptions.receiptHeader", rh);
        } else {
          this.set("model.eventOptions.receiptHeader", "");
        }
      },

      toggleLotSellable: function toggleLotSellable(lot) {
        lot.lot.toggleProperty('sellable');

        if (lot.lot.get('sellable')) {
          this.get('model.lotsSellable').pushObject(lot.lot);
          this.send('updateLotsSellable', lot.lot);

          if (lot.pricings.content.length === 0) {
            _ember["default"].$("#" + lot.lot.id + " .tag-cluster__item .modify-pp").click();
          }
        } else {
          this.get('model.lotsSellable').removeObject(lot.lot);

          return this.send('updateLotsSellable', lot.lot);
        }
      },

      toggleOptOut: function toggleOptOut() {
        this.toggleProperty("model.event.live");

        this.set('model.event.eventOptions.optOut', !this.get("model.event.live"));

        this.send('saveOptOutPreference');
      },

      updateLotsSellable: function updateLotsSellable(lot) {
        var _this = this;

        var event = _ember["default"].Object.create({
          id: this.get('model.event.id')
        });

        _ember["default"].set(event, "eventOptions", {
          id: this.get('model.event.eventOptions.id'),
          lotsSellable: this.get('model.lotsSellable')
        });

        this.get('store').adapterFor('event-option').updateRecord(this.get('store'), 'event-option', { event: event }).then(function () {
          if (lot.get('sellable')) {
            _this.flashMessages.success("You have enabled selling for " + lot.get('name'));
          } else {
            _this.flashMessages.success("You have disabled selling for " + lot.get('name'));
          }
        })["catch"](function (err) {
          _this.flashMessages.danger("Could not enable selling for  " + lot.get('name'));
        });
      },

      saveOptOutPreference: function saveOptOutPreference() {
        var _this2 = this;

        var event = _ember["default"].Object.create({
          id: this.get('model.event.id'),
          live: this.get('model.event.live')
        });

        _ember["default"].set(event, "eventOptions", {
          id: this.get('model.event.eventOptions.id'),
          optOut: !this.get('model.event.live'),
          lotsSellable: this.get('model.lotsSellable')
        });

        this.get('store').adapterFor('event-option').updateRecord(this.get('store'), 'event-option', { event: event }).then(function () {
          _this2.flashMessages.success("You have opted out from selling for this event.");
        })["catch"](function (err) {
          _this2.flashMessages.danger("Error opting out of your event.");
        });
      },

      saveEventOptions: function saveEventOptions() {
        var _this3 = this;

        var event = _ember["default"].Object.create({
          live: this.get("model.live")
        });

        _ember["default"].set(event, "eventOptions", {
          id: this.get("model.eventOptions.id"),
          phoneNumber: this.get("model.eventOptions.phoneNumber") || '',
          receiptHeader: this.get("model.eventOptions.receiptHeader") || '',
          receiptFooter: this.get("model.eventOptions.receiptFooter") || ''
        });

        var promise = (0, _fetch["default"])(_webConfigEnvironment["default"].apiUrl + "/api/v3/events/" + this.get("model.event.id"), {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ event: event }),
          credentials: "include"
        }).then(function () {
          _this3.flashMessages.success("Event settings updated successfully.");
        })["catch"](function () {
          _this3.flashMessages.danger("Could not update event settings.");
        });
      },

      eventTransition: function eventTransition(eventId) {
        this.transitionToRoute("event.details", eventId);
      },

      modifyPricingCluster: function modifyPricingCluster(tag, lot, pricings, type, title, userOptions, formattedPricings) {
        var itemModel = { tag: tag, lot: lot, pricings: pricings, type: type, title: title, userOptions: userOptions, formattedPricings: formattedPricings };

        this.get('modifyPricing').setProperties({
          model: itemModel,
          type: type
        });
        this.send('openModal', 'pricing');
      }
    }
  });
});
define('web/controllers/lot/details', ['exports', 'ember', 'web/mixins/record-editor', 'web/mixins/state-options', 'web/mixins/set-lat-lon-controller', 'web/mixins/image-uploader-controller', 'web/helpers/slugify', 'web/utils/country-options'], function (exports, _ember, _webMixinsRecordEditor, _webMixinsStateOptions, _webMixinsSetLatLonController, _webMixinsImageUploaderController, _webHelpersSlugify, _webUtilsCountryOptions) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], _webMixinsStateOptions['default'], _webMixinsSetLatLonController['default'], _webMixinsImageUploaderController['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: computed.alias('model.id'),
    recordName: 'lot',
    recordModel: 'lot',
    deleteTransitionTo: 'lots',
    typeAheadBelongsTo: ['operator', 'regionId', 'paymentProcessorId'],
    hideDeleteOption: true,
    assignAllNewAttendants: computed.alias('model.assignAllNewAttendants'),
    selectedMetatag: computed.alias('model.selectedMetatag'),

    /* determine available metatags to show in front end. */
    availableMetatagLabels: computed('metatags', {
      get: function get() {

        /* get metatag objects from lot.js route */
        var metatagObject = this.get('metatags');

        /* ensure metatag service is running */
        if (metatagObject.children.length === 0) {
          return [];
        } else {
          /* get an array of labels from the objects */
          var metatagLabels = metatagObject.children.map(function (response) {
            return response.displayName;
          });
          return metatagLabels;
        }
      }
    }),
    availableCountries: _webUtilsCountryOptions.availableCountries,
    selectedCountry: computed('model.country', function () {
      if (!this.get('model.country')) {
        return null;
      }

      return this.get('availableCountries').findBy('id', this.get('model.country'));
    }),
    query: function query(text, selectedItems, cb, type, _ref) {
      var exclude = _ref.exclude;
      var role = _ref.role;

      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      if (_ember['default'].isPresent(exclude)) {
        params.exclude = exclude;
      }
      if (_ember['default'].isPresent(role)) {
        params.role = role;
      }

      if (type === 'landmark') {
        params.dBSearch = true;
      }

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      toggleAssignAllAttendants: function toggleAssignAllAttendants() {
        this.toggleProperty('model.assignAllAttendants');
      },

      toggleSelected: function toggleSelected() {
        this.toggleProperty('model.live');
      },

      togglePresell: function togglePresell() {
        this.toggleProperty('model.presell');
      },

      toggleSell: function toggleSell() {
        this.toggleProperty('model.autoSell');
      },

      changeName: function changeName(name) {
        var slugged = (0, _webHelpersSlugify.slugify)(name);
        this.set('model.slug', slugged);
        this.set('model.name', name);
      },

      changeSlug: function changeSlug(slug) {
        this.set('model.slug', (0, _webHelpersSlugify.slugify)(slug));
      },

      changeCountry: function changeCountry(country) {
        this.set('model.country', country.id);
        this._setLatLong(this.get('model.zip'));
      },

      changeZip: function changeZip(zip) {
        this.set('model.zip', zip);
        this._setLatLong(zip);
      },

      changeMetatag: function changeMetatag(metatagLabel) {
        var metatagObject = this.get('metatags');

        /* Find the metatag path that corresponds with the label chosen */
        var metatag = metatagObject.children.find(function (element) {
          return element.displayName === metatagLabel;
        });

        this.set('updatedMetatag', metatag.displayName);
        this.set('model.inventoryTypePath', metatag.path);
      },

      subOpRequest: function subOpRequest(text, selectedItems, cb) {
        var s = selectedItems.mapBy('id');
        var r = 'sub-operator';

        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'user', { exclude: s, role: r });
      },

      subOpFocused: function subOpFocused(curSelectedItems, cb) {
        var r = 'sub-operator';

        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'user', { exclude: null, role: r });
        }
      },

      attendantRequest: function attendantRequest(text, selectedItems, cb) {
        // const s = selectedItems.mapBy('id');
        var r = 'attendant';

        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'user', { exclude: null, role: r });
      },

      attendantFocused: function attendantFocused(curSelectedItems, cb) {
        var r = 'attendant';

        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'user', { exclude: null, role: r });
        }
      },

      updateAddAllAttendants: function updateAddAllAttendants(val, checked) {
        this.set('model.assignAllAttendants', checked);
      },

      updateAddAllNewAttendants: function updateAddAllNewAttendants(val, checked) {
        this.set('model.assignAllNewAttendants', checked);
      },

      operatorRequest: function operatorRequest(text, selectedItems, cb) {
        var r = 'operator';

        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'user', { exclude: null, role: r });
      },

      operatorFocused: function operatorFocused(curSelectedItems, cb) {
        var r = 'operator';

        if (_ember['default'].isBlank(curSelectedItems) || _ember['default'].isBlank(curSelectedItems.content)) {
          this.query('*', null, cb, 'user', { exclude: null, role: r });
        }
      },

      landmarkRequest: function landmarkRequest(text, selectedItems, cb) {
        var s = selectedItems.mapBy('id');

        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'landmark', { exclude: s });
      },

      landmarkFocused: function landmarkFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'landmark', {});
        }
      },

      regionRequest: function regionRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'region', {});
      },

      regionFocused: function regionFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems) || _ember['default'].isBlank(curSelectedItems.content)) {
          this.query('*', null, cb, 'region', {});
        }
      },

      valSetFocused: function valSetFocused(items) {
        if (_ember['default'].isNone(items)) {
          this.set('model.externalLotNames', []);
        }
      },

      changeChecked: function changeChecked(newVal, checked) {
        if (checked) {
          return this.get('model.amenities').pushObject(newVal);
        }
        this.get('model.amenities').removeObject(newVal);
      }
    }
  });
});
define('web/controllers/create/region', ['exports', 'ember', 'web/mixins/record-create', 'web/config/environment', 'ic-ajax', 'web/utils/country-options'], function (exports, _ember, _webMixinsRecordCreate, _webConfigEnvironment, _icAjax, _webUtilsCountryOptions) {
  var debounce = _ember['default'].run.debounce;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var observer = _ember['default'].observer;

  var m = _webConfigEnvironment['default'].mapbox;
  var mapboxPlaces = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/';
  var types = 'place,postcode';
  var tokenString = '.json?types=' + types + '&access_token=' + m.accessToken;

  var BaseMarkerOptions = {
    icon: {
      iconSize: [16, 16],
      popupAnchor: [0, -16]
    }
  };

  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordCreate.ControllerRecordCreate, {
    availableCountries: _webUtilsCountryOptions.availableCountries,
    selectedCountry: computed('model.country', function () {
      if (!this.get('model.country')) {
        return null;
      }

      return this.get('availableCountries').findBy('id', this.get('model.country'));
    }),
    _getLatLon: function _getLatLon(zip) {
      var _this = this;

      if (zip.length > 4) {
        (0, _icAjax['default'])('' + mapboxPlaces + this.get('model.name') + ' ' + zip + tokenString).then(function (resp) {
          var feature = resp.features[0];

          if (!feature) {
            return;
          }

          _this.set('model.location', _ember['default'].Object.create({
            lon: feature.center[0],
            lat: feature.center[1]
          }));

          var country = feature.place_name.split(', ').pop();
          var countryMap = {
            'United States': 'US',
            'United Kingdom': 'GB'
          };

          _this.set('model.country', countryMap[country] || country);
        });
      }
    },

    markerOptions: computed(function () {
      var markerLocationOptions = copy(BaseMarkerOptions);
      markerLocationOptions.icon.iconUrl = 'assets/images/map-point-blue.svg';

      return markerLocationOptions;
    }),

    convertMiToKm: observer('model.radius', function () {
      debounce(this, this._convert, this.get('model.radius'), 750);
    }),

    _convert: function _convert(rad) {
      this.set('miToKm', parseInt(rad) * 1.60934 * 1000);
    },

    actions: {
      changeZip: function changeZip(zip) {
        if (_ember['default'].isBlank(zip)) {
          this.set('model.zip', null);
          this.set('model.location', null);
        } else {
          this.set('model.zip', zip);
          debounce(this, this._getLatLon, zip, 750);
        }
      },
      changeCountry: function changeCountry(country) {
        this.set('model.country', country.id);
        this._setLatLong(this.get('model.zip'));
      }
    }
  });
});
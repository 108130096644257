define('web/controllers/external-transactions/failed-barcodes', ['exports', 'ember', 'web/mixins/load-more', 'web/config/environment'], function (exports, _ember, _webMixinsLoadMore, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsLoadMore.ControllerLoadMore, {
    queryParams: ['limit', 'offset'],
    recordName: 'failedExternalTransaction',

    stagedForRetry: [],
    showDialog: false,
    tooltipEventId: null,

    barcodeCols: [{}, { colName: 'Retried' }, { colName: 'Barcode' }, { colName: 'Source' }, { colName: 'Error Message' }, { colName: 'Date / Time' }, {}],

    clear: function clear() {
      this.set('stagedForRetry', []);
    },

    actions: {
      changeChecked: function changeChecked(newVal, checked) {
        if (checked) {
          return this.get('stagedForRetry').pushObject(newVal);
        }
        this.get('stagedForRetry').removeObject(newVal);
      },

      showTooltip: function showTooltip(id) {
        this.toggleProperty('showDialog');
        this.set('tooltipEventId', id);
      },

      showDialog: function showDialog() {
        return;
      },

      retryBarcodes: function retryBarcodes(cb) {
        var _this = this;

        var ids = this.get('stagedForRetry').mapBy('id');

        var promise = _ember['default'].RSVP.all(ids.map(function (id) {
          var payload = new Object({
            failedExternalTransaction: {
              retried: true
            }
          });

          return fetch(_webConfigEnvironment['default'].apiUrl + '/api/v3/failedExternalTransactions/' + id, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(payload)
          });
        }));

        cb(promise);

        promise.then(function (responses) {
          responses.map(function (resp) {
            if (resp.status >= 300) {
              throw resp;
            }
            return resp;
          });
        }).then(function (success) {
          _ember['default'].run.later(function () {
            _this.clear();
            _this.send('invalidateModel');
          }, 3000);
        });
      }
    }
  });
});
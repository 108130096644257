define('web/helpers/or-dash-currency', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.orDashCurrency = orDashCurrency;

  function orDashCurrency(_ref) {
    var _ref2 = _slicedToArray(_ref, 3);

    var input = _ref2[0];
    var num = _ref2[1];
    var country = _ref2[2];

    if (_ember['default'].isPresent(input) && _ember['default'].isPresent(num)) {
      var currencyType = country === 'GB' ? '£' : '$';

      if (input === null || input === undefined) {
        var output = '-';
        if (num) {
          while (--num > 0) {
            output += '-';
          }
        }

        return output;
      }

      if (_ember['default'].typeOf(input) === 'string') {
        input = parseFloat(input);
      }

      if (!num) {
        num = 0;
      }

      return currencyType + input.toFixed(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  exports['default'] = _ember['default'].Helper.helper(orDashCurrency);
});
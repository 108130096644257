define('web/adapters/application', ['exports', 'ember-data', 'ember', 'web/config/environment'], function (exports, _emberData, _ember, _webConfigEnvironment) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    user: _ember['default'].inject.service('current-user'),
    host: _webConfigEnvironment['default'].apiUrl,
    namespace: 'api/v3',

    ajax: function ajax(url, method, hash) {
      hash = hash || {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    headers: (function () {
      return {
        'Timezone': this.get('user.timezone')
      };
    }).property().volatile(),

    handleResponse: function handleResponse(status, headers, payload) {
      var possibleErrors = [400, 401, 403, 404, 409, 500];
      if (possibleErrors.contains(status)) {
        return payload;
      }
      return this._super.apply(this, arguments);
    }
  });
});
define('web/controllers/external-transactions/receipt-barcodes', ['exports', 'ember', 'fetch', 'web/config/environment'], function (exports, _ember, _fetch, _webConfigEnvironment) {
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Controller.extend({
    receiptBarcodesModal: _ember['default'].inject.controller('modals.receipt-barcodes'),
    supportedFileTypes: ['*.csv', '*.txt'],
    selectedEvent: null,

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      eventRequest: function eventRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'event');
      },

      eventSelected: function eventSelected(item, cb) {
        cb(item);
      },

      uploadBarcodes: function uploadBarcodes(file, callback) {
        var _this = this;

        this.set('isLoading', true);

        var obj = {
          file: file
        };

        if (get(this, 'selectedEvent.id')) {
          obj['eventId'] = get(this, 'selectedEvent.id');
        }

        var formData = new FormData();

        for (var key in obj) {
          formData.append(key, obj[key]);
        }

        var clear = function clear() {
          _this.set('isLoading', false);
          _this.set('selectedEvent', null);
        };

        if (obj.file) {
          (0, _fetch['default'])(_webConfigEnvironment['default'].apiUrl + '/api/v3/receiptBarcodes', {
            method: 'POST',
            headers: {
              'Process-Data': false
            },
            credentials: 'include',
            body: formData
          }).then(function (status) {
            if (status.status > 300) {
              throw status;
            } else {
              return status;
            }
          }).then(function (resp) {
            _this.set('finishedLoading', true);
            clear();
            callback(resp);

            run.later(function () {
              _this.set('finishedLoading', false);
              resp.json().then(function (payload) {
                _this.set('receiptBarcodesModal.model', payload);
                _this.send('openModal', 'receipt-barcodes');
              });
            }, 1000);
          })['catch'](function (err) {
            err.json().then(function (resp) {
              _this.flashMessages.danger(resp.message);
              clear();
              callback(err);
            });
          });
        }
      }
    }

  });
});
define('web/routes/create/lot', ['exports', 'ember', 'ic-ajax', 'web/mixins/record-create', 'web/config/environment'], function (exports, _ember, _icAjax, _webMixinsRecordCreate, _webConfigEnvironment) {

  /* Retrieve the metatags available from the meta-tagging-service */
  var metatags = (0, _icAjax['default'])({
    method: 'GET',
    url: _webConfigEnvironment['default'].metatagUrl + '/tags/tag.inventory_type',
    contentType: 'application/json',
    dataType: 'json',
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    }
  }).then(function (response) {
    return response;
  })['catch'](function (e) {
    return { children: [] };
  }); // or set it to blank array if service is down

  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ['name', 'slug', 'address1', 'address2', 'city', 'state', 'zip', 'location', 'directions', 'information', 'totalSpots', 'autoSell', 'inventoryTypePath', 'country'],
    recordHasMany: ['landmarks', 'amenities', 'subOperators'],
    recordBelongsTo: ['regionId', 'operator', 'paymentProcessorId'],

    model: function model() {
      return _ember['default'].RSVP.hash({
        landmarks: [],
        amenities: [],
        subOperators: [],
        autoSell: true,
        availableAmenities: this.store.findAll('amenity'),
        availablePaymentProcessors: this.store.findAll('payment-processor'),
        metatags: metatags
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('availablePaymentProcessors', model.availablePaymentProcessors);
      controller.set('availableAmenities', model.availableAmenities);
      controller.set('metatags', model.metatags);
    },

    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      this.controller.setProperties({
        landmarkSearch: null,
        regionSearch: null,
        subOperatorsSearch: null,
        operatorSearch: null,
        errorMessages: null
      });
    }
  });
});